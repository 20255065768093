import { required, email } from 'vuelidate/lib/validators'

export default {
  emails: {
    $each: {
      address: {
        required,
        email
      }
    }
  }
}
