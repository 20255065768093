import { required } from 'vuelidate/lib/validators'

export default {
  addresses: {
    $each: {
      address1: {
        required
      },
      suburbText: {
        required
      },
      stateCode: {
        required
      },
      stateText: {
        required
      },
      suburbPostcode: {
        required
      },
      countryCode: {
        required
      },
      countryText: {
        required
      }
    }
  }
}
