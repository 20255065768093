import { required } from 'vuelidate/lib/validators'

export default {
  phones: {
    $each: {
      no: {
        required
      }
    }
  }
}
